<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Examinations
            <v-spacer></v-spacer>
            <transition
              name="animate-css-transition"
              enter-active-class="animated fadeInRight"
              leave-active-class="animated fadeOutRight"
            >
              <add-button
                permission="exam-create"
                @action="
                  (form.dialog = true), $refs.form ? $refs.form.reset() : ''
                "
              >
                &nbsp; New Exam
              </add-button>
            </transition>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :hide-default-footer="form.items.data.length < 11"
            :items="form.items.data"
            footer-props.items-per-page-options="rowsPerPageItems"
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  <strong>
                    {{ item.name }}
                    <v-icon small color="success" v-if="item.is_final === 1"
                      >check_circle</v-icon
                    >
                  </strong>
                  <br />
                  <small style="color: #777;"
                    >{{ item.is_final === 1 ? "Marked as Final Terminal" : "" }}
                  </small>
                </td>
                <td class="text-xs-left">
                  {{ item.exam_type === "exam" ? "Exam" : "Test" }}
                </td>
                <td class="text-right">
                  <view-button
                    icon="supervised_user_circle"
                    permission="exam-read"
                    label="Attendance"
                    @click.native="viewAttendance(item)"
                  />&nbsp;
                  <view-button
                    permission="exam-read"
                    @click.native="viewExam(item.id)"
                  />
                  <edit-button
                    permission="exam-update"
                    @agree="form.edit(item), editExam(item)"
                  />
                  <delete-button
                    permission="exam-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog v-model="form.dialog" persistent max-width="500px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text class="pb-2">
            <br />
            <alert-message
              :extraSmall="true"
              :title="batch.name"
              :message="
                'Exam is being ' +
                  (this.form.id ? 'updated' : 'created') +
                  ' for: ' +
                  batch.name
              "
            ></alert-message>
            <v-form
              ref="form"
              @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
              v-model="valid"
              :lazy-validation="lazy"
            >
              <br />
              <v-select
                :items="exam_original_name_types"
                class="pa-0 pt-3"
                label="Exam Type"
                v-model="form.exam_original_name"
                outlined
                dense
              />

              <v-text-field
                autocomplete="off"
                label="Exam Name*"
                hint="Eg: First Terminal Examinations"
                required
                class="pa-0 pt-3"
                v-model="form.name"
                name="name"
                :error-messages="form.errors.get('name')"
                outlined
                dense
                :rules="[(v) => !!v || 'Exam Name is required']"
              />

              <v-select
                :items="examType"
                class="pa-0 pt-3"
                label="Select Type"
                v-model="form.exam_type"
                outlined
                dense
                :rules="[(v) => !!v || 'Exam Type is required']"
              />

              <v-flex xs8>
                <v-checkbox
                  v-if="form.exam_type === 'exam'"
                  color="blue"
                  v-model="is_final"
                >
                  <template v-slot:label>
                    <div style="color:#333;">
                      <!--Subject will be-->
                      <strong>{{
                        is_final === false ? "Check to mark as" : "Marked as "
                      }}</strong>
                      final terminal.
                      <p style="line-height: 10px">
                        <small style="color:#666;font-size: 10px;">
                          One among various examinations should be marked as
                          final.
                        </small>
                      </p>
                    </div>
                  </template>
                </v-checkbox>
              </v-flex>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              @click="(form.dialog = false), form.reset()"
              >Close</v-btn
            >
            <v-btn color="success" text @click="validate">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";

export default {
  data: () => ({
    valid: true,
    lazy: false,
    form: new Form(
      {
        name: "",
        is_final: 0,
        exam_type: "",
        exam_original_name: "",
        is_college_batch: "",
      },
      "/api/exam"
    ),
    exam_original_name_types: [
      "First Term Exam",
      "Second Term Exam",
      "Third Term Exam",
      "Internal Evaluation",
    ],
    search: null,
    rowsPerPageItems: [5, 10, 25, 50, 75],
    pagination: {
      rowsPerPage: 10,
    },
    examType: [
      { value: "exam", text: "Exam" },
      { value: "test-exam", text: "Test" },
    ],
    is_final: false,
    headers: [
      { text: "#", align: "center", value: "id", width: 50, sortable: false },
      { text: "Name", align: "left", value: "name" },
      { text: "Exam Type", align: "left", value: "exam_type" },
      { text: "Action", align: "right", sortable: false, width: 400 },
    ],
  }),

  computed: {
    ...mapState(["batch"]),
  },

  watch: {
    pagination: function() {
      this.get();
    },
    batch: function(value) {
      this.get();
    },
    "form.exam_original_name": function(value) {
      if (!this.form.name || !this.form.id) {
        this.form.name = value;
      }
    },
  },

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function(key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&")
      );
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },

    save() {
      if (this.is_final) this.form.is_final = 1;
      else this.form.is_final = 0;

      this.form.is_college_batch = this.batch.is_hs === true ? 1 : 0;

      this.form.store().then(() => {
        this.form.is_final = 0;
      });
    },

    editExam(item) {
      if (item.is_final === 1) this.is_final = true;
      else this.is_final = false;
    },
    viewExam(id) {
      this.$router.push({
        name: "exam-detail",
        params: {
          examId: id,
        },
      });
    },
    viewAttendance(item) {
      let query = { id: item.id, name: item.name };
      this.$router.push({
        name: "attendance-setting",
        query: query,
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
